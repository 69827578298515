import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SearchBar from './SearchBar';
import { css } from '@emotion/react';
import searchStyle from '../styles/search.scss'
import { useIntl } from "gatsby-plugin-intl"

const Search = ({ query, style }) => {
  const { formatMessage } = useIntl();
  const id = decodeURI(query);
  const [regex, setRegex] = useState(null);

  useEffect(() => {
    if (query) {
      const s = `(?<=<p>).*${query}.*(?=<\/p>)`;
      const r = new RegExp(s, 'gi');
      setRegex(r);
    }
  }, [query]);

  const data = useStaticQuery(graphql`
    query searchQueryFilter {
      allWpPage {
        nodes {
          id
          uri
          title
          slug
          date(formatString: "DD MMMM YY")
          content
        }
      }
      allWpService{
          nodes {
            id
            parentId
            content
            title
            slug
            uri
            servicesFields {
              fieldGroupName
              headerBackgroundImage {
                altText
                title
                sourceUrl
              }
              listingButtonLabel
              resourceSection {
                fieldGroupName
                resourceSectionTitle
                resourceList {
                  icon {
                    sourceUrl
                    altText
                  }
                  title
                  fieldGroupName
                }
              }
              listingButtonImage {
                altText
                title
                sourceUrl
              }
            }
          }
        }
    }  
  `);

  const updateData = Object.entries(data).reduce((acc, [key, value], i) => {
    if (Array.isArray(data[key].nodes)) {
      const item = data[key].nodes.filter((node) => {
        if (node.title && (node.title.replace(/<\/?[^>]+(>|$)/g, "")).toLowerCase().includes(id.toLowerCase())) {
          return ((node.title.replace(/<\/?[^>]+(>|$)/g, "")).toLowerCase().includes(id.toLowerCase()));
        } else if (Array.isArray(node.servicesFields?.resourceSection)) {
          return (node.servicesFields.resourceSection.some(section => {
            return (section.resourceSectionTitle && (section.resourceSectionTitle.replace(/<\/?[^>]+(>|$)/g, "")).toLowerCase().includes(id.toLowerCase())) ||
              (section.resourceList && section.resourceList.some(resourceList => resourceList.title.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase().includes(id.toLowerCase())))
          }))
        } else if (node.content) {
          return (node.content.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase()).includes(id.toLowerCase());
        }
      })
      return [...acc, ...item]
    };
    return []
  }, []);

  const getMatchingExcerpt = (string, regex = id) => {
    const matchingText = ((string.match(regex) || [])[0] || string).replace(/<\/?[^>]+(>|$)/g, '').trim();
    return (editText(matchingText))
  }

  function truncate(str, number) {
    return "... " + str.split(" ").splice(0, number).join(" ") + ' ...';
  }

  const editText = (matchingText) => {
    let totalArry = matchingText.toLowerCase().split(" ");
    const indexofWord = totalArry.indexOf(id.toLowerCase());
    if (indexofWord > -1) {
      totalArry.splice(0, indexofWord - 2);
      const betweenWordsArr = totalArry.slice(0, 150)
      betweenWordsArr.push(['... '])
      betweenWordsArr.unshift('... ')
      matchingText = betweenWordsArr.join(" ")
    }
    if (indexofWord === -1) {
      matchingText = (truncate(matchingText, 150))
    }
    return matchingText
  }

  return (
    <div css={[css(searchStyle), css(style)]} className='search-container'>
      <h1>{formatMessage({ id: 'searchResults' })}</h1>
      <SearchBar id={id} />
      <p className='totalSearch'>{`${updateData.length} ${formatMessage({ id: 'searchTotal' })} `}<span>{`'${id}'`}</span></p>
      {
        updateData.length > 0 && updateData.map((section, i) => (
          <div className='search-results' key={i}>
            <a href={section.uri}>
              <h2>{section.title}</h2>
            </a>
            {
              section.content ? (
                <p dangerouslySetInnerHTML={{
                  __html: getMatchingExcerpt(section.content, regex)
                    || getMatchingExcerpt(section.content, /(?<=<p>).*(?=<\/p>)/g)
                }} />
              ) : section.servicesFields && section.servicesFields?.resourceSection ? (
                <p>{`... ${section.servicesFields?.resourceSection[0].resourceSectionTitle} ...`}</p>
              ) : (<p>...</p>)
            }
          </div>
        ))
      }
    </div >
  )
}

const SearchStyled = (props) => (
  <Search {...props} style={props.style} />
);

Search.propTypes = {
  query: PropTypes.string,
  style: PropTypes.string
};

Search.defaultProps = {
  query: '',
  style: ''
};

SearchStyled.propTypes = {
  query: PropTypes.string,
  style: PropTypes.string
};

SearchStyled.defaultProps = {
  query: '',
  style: ''
};

export default SearchStyled;
