import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import Search from '../components/Search';
import Seo from '../components/Seo';

const SearchPage = (props) => {
  const { location: { search = "" } = {}, params = {}, style } = props;
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(window.location.hash.replace("#", ""))
  }, [search, params]);

  return (
    <div>
      <Layout style={style}>
        <Seo title={`Search Results - ${query}`} />
        <Search query={query} />
      </Layout>
    </div>
  )
}

const SearchPageStyled = (props) => (
  <SearchPage {...props} style={style} />
);

const style = `
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  @media screen and (min-width: 1200px) {
    grid-template-rows: auto auto 1fr auto;
  }
`

SearchPage.propTypes = {
  params: PropTypes.shape({}),
  location: PropTypes.shape({}),
  style: PropTypes.string
};

SearchPage.defaultProps = {
  params: {},
  location: {},
  style: ''
};

SearchPageStyled.propTypes = {
  params: PropTypes.shape({}),
  location: PropTypes.shape({}),
};

SearchPageStyled.defaultProps = {
  params: {},
  location: {}
};

export default SearchPageStyled;
